body {
    font-family: 'Architects Daughter', cursive;
    background: linear-gradient(180deg, #C6C5FF, #C5E3FF 8%, #ECECEC, #FFE0C3, #CFFFAA, #CFFFAA );
    min-height: 100dvh;
}

*{
    letter-spacing: 4px;
}

*::-webkit-scrollbar {
    display: none;
}

.fadeOpacity {
    animation: fadeOpacity 3s ease-in-out;
}

@keyframes fadeOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}