@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    h1 {
        @apply text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl
    }

    h2 {
        @apply text-3xl sm:text-4xl md:text-5xl lg:text-6xl 2xl:text-7xl
    }

    p {
        @apply text-xl sm:text-3xl 2xl:text-4xl
    }
}